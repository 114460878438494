import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { createStructuredSelector } from "reselect";
import {
  addDoc,
  collection,
  query,
  where,
  orderBy,
  deleteDoc,
  doc,
  getDocs,
  getDoc,
  updateDoc,
  Timestamp,
  onSnapshot,
  or,
  and,
  documentId,
  setDoc,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import {
  ref as firebaseStorageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import { sortBy, chain } from "underscore";

import { database, functions, storage } from "../../firebase";
import { usersSliceSelector } from "./usersSlice";
import {
  deserialize,
  serialize,
  getValidatedDoc,
  parseFunctionsResponse,
  awaitDocCreation,
} from "./utils";
import { organizationsSliceSelector } from "../../redux/features/organizationsSlice";
import React from "react";
import { utcToZonedTime } from "date-fns-tz";
import { startOfDay } from "date-fns";
import { addDays } from "date-fns";
import { setHours } from "date-fns";
import { setMinutes } from "date-fns";
import { addMilliseconds } from "date-fns";
import getSlug from "speakingurl";
import { v4 as uuidv4 } from "uuid";

const millisecondsPerDay = 8.64e7;
const millisecondsPerHour = 3.6e6;

const initialState = {
  newEvent: null,
  duplicatedEvent: null,
  events: {},
  eventStats: {},
  tickets: {},
  eventsLoading: {},
  eventsListLoading: false,
  eventsListFetched: false,
  duplicatingEvent: false,
  eventUpdateErrors: {},
  tickets: {},
  ticketsCheckingIn: {},
  ticketsCheckingOut: {},
  ticketTypeStats: {},
  checkInsPending: {},
  orders: {},
  promoCodes: {},
  affiliates: {},
  sessions: {},
};

const adminEventsSlice = createSlice({
  name: "adminEvents",
  initialState,
  reducers: {
    resetEvents: () => initialState,
    clearNewEvent(state, action) {
      state.newEvent = null;
    },
    clearDuplicatedEvent(state, action) {
      state.duplicatedEvent = null;
    },
    setEventUpdateError(state, action) {
      if (action.payload) {
        const { eventId, error } = action.payload;
        state.eventUpdateErrors[eventId] = error;
      }
    },
    setEventLoading(state, action) {
      if (action.payload) {
        const { eventId, loading } = action.payload;
        state.eventsLoading[eventId] = loading;
      }
    },
    setEvent(state, action) {
      if (action.payload) {
        const { eventId, event } = action.payload;
        state.events[eventId] = event;
      }
    },
    receiveTickets(state, action) {
      if (action.payload) {
        const { eventId, tickets } = action.payload;
        const ticketIds = tickets.map(({ id }) => id);
        const existingTicketsWithoutNewTickets = (
          state.tickets[eventId] || []
        ).filter(({ id }) => !ticketIds.includes(id));
        state.tickets[eventId] = [
          ...existingTicketsWithoutNewTickets,
          ...tickets,
        ];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(buildNewEvent.fulfilled, (state, action) => {
        if (action.payload) {
          const { newEvent } = action.payload;
          state.newEvent = newEvent;
        }
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        if (action.payload) {
          const { newEvent } = action.payload;
          state.newEvent = newEvent;
          state.events[newEvent.id] = newEvent;
          state.eventsLoading.new = false;
        }
      })
      .addCase(createEvent.pending, (state, { meta }) => {
        state.eventsLoading.new = true;
      })
      .addCase(sendTicketEmailToAttendees.fulfilled, (state, action) => {
        if (action.payload) {
          const { eventId } = action.payload;
          if (eventId) {
            state.eventsLoading[eventId] = false;
          }
        }
      })
      .addCase(sendTicketEmailToAttendees.pending, (state, { meta }) => {
        const { eventId } = meta.arg;
        if (eventId) {
          state.eventsLoading[eventId] = true;
        }
      })
      .addCase(sendOrderConfirmationEmail.fulfilled, (state, action) => {
        if (action.payload) {
          const { eventId } = action.payload;
          if (eventId) {
            state.eventsLoading[eventId] = false;
          }
        }
      })
      .addCase(sendOrderConfirmationEmail.pending, (state, { meta }) => {
        const { eventId } = meta.arg;
        if (eventId) {
          state.eventsLoading[eventId] = true;
        }
      })
      .addCase(duplicateEvent.fulfilled, (state, action) => {
        if (action.payload) {
          const { newEvent, eventStats } = action.payload;
          state.duplicatedEvent = newEvent;
          state.events[newEvent.id] = newEvent;
          state.eventStats[newEvent.id] = eventStats;
          state.duplicatingEvent = false;
        }
      })
      .addCase(duplicateEvent.pending, (state, { meta }) => {
        state.duplicatingEvent = true;
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        if (action.payload) {
          const { event, eventId } = action.payload;
          state.events[eventId] = event;
          state.eventsLoading[eventId] = false;
        }
      })
      .addCase(updateEvent.pending, (state, { meta }) => {
        const { eventId } = meta.arg;
        state.eventsLoading[eventId] = true;
      })
      .addCase(updateEventStats.fulfilled, (state, action) => {
        if (action.payload) {
          const { eventStats, eventId } = action.payload;
          state.eventStats[eventId] = eventStats;
          state.eventsLoading[eventId] = false;
        }
      })
      .addCase(updateEventStats.pending, (state, { meta }) => {
        const { eventId } = meta.arg;
        state.eventsLoading[eventId] = true;
      })
      .addCase(createTicketType.pending, (state, { meta }) => {
        const { eventId } = meta.arg;
        state.eventsLoading[eventId] = true;
      })
      .addCase(importTicketType.pending, (state, { meta }) => {
        const { eventId } = meta.arg;
        state.eventsLoading[eventId] = true;
      })
      .addCase(updateTicketType.pending, (state, { meta }) => {
        const { eventId } = meta.arg;
        state.eventsLoading[eventId] = true;
      })
      .addCase(addPerformerToEvent.pending, (state, { meta }) => {
        const { eventId } = meta.arg;
        state.eventsLoading[eventId] = true;
      })
      .addCase(removePerformerFromEvent.pending, (state, { meta }) => {
        const { eventId } = meta.arg;
        state.eventsLoading[eventId] = true;
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        if (action.payload) {
          const { events, eventStats } = action.payload;
          state.events = { ...state.events, ...events };
          state.eventStats = { ...state.eventStats, ...eventStats };
          state.eventsListLoading = false;
          state.eventsListFetched = true;
        }
      })
      .addCase(fetchEvents.pending, (state, { meta }) => {
        state.events = {};
        state.eventsListLoading = true;
      })
      .addCase(fetchEventById.fulfilled, (state, action) => {
        if (action.payload) {
          const { eventId, event, eventStats } = action.payload;
          console.log("doc length", JSON.stringify(event).length / 1000000);
          state.events[eventId] = event;
          state.eventStats[eventId] = eventStats;
          state.eventsLoading[eventId] = false;
        }
      })
      .addCase(fetchEventById.pending, (state, { meta }) => {
        const { eventId } = meta.arg;
        state.eventsLoading[eventId] = true;
      })
      .addCase(setEventPublished.fulfilled, (state, action) => {
        if (action.payload) {
          const { eventId, event } = action.payload;
          state.events[eventId] = event;
          state.eventsLoading[eventId] = false;
        }
      })
      .addCase(setEventPublished.pending, (state, { meta }) => {
        const { eventId } = meta.arg;
        state.eventsLoading[eventId] = true;
      })
      .addCase(checkInTicket.fulfilled, (state, action) => {
        if (action.payload) {
          const { eventId, ticketId, checkedIn } = action.payload;
          if (checkedIn) {
            state.ticketsCheckingIn[ticketId] = false;
          } else {
            state.ticketsCheckingOut[ticketId] = false;
          }
        }
      })
      .addCase(checkInTicket.pending, (state, { meta }) => {
        const { ticketId, checkedIn } = meta.arg;
        if (checkedIn) {
          state.ticketsCheckingIn[ticketId] = true;
        } else {
          state.ticketsCheckingOut[ticketId] = true;
        }
      })
      // .addCase(checkInTicket.rejected, (state, { meta }) => {
      //   const { ticketId } = meta.arg;
      //   state.ticketsCheckingIn[ticketId] = true;
      // })
      .addCase(fetchTicketTypeStatsForEvent.fulfilled, (state, action) => {
        if (action.payload) {
          const { eventId, ticketTypeStats } = action.payload;
          state.ticketTypeStats[eventId] = ticketTypeStats;
        }
      })
      .addCase(fetchOrdersForEvent.pending, (state, { meta }) => {
        const { eventId } = meta.arg;
        state.eventsLoading[eventId] = true;
      })
      .addCase(fetchOrdersForEvent.fulfilled, (state, action) => {
        if (action.payload) {
          const { eventId, orders } = action.payload;
          state.orders[eventId] = orders;
          state.eventsLoading[eventId] = false;
        }
      })
      .addCase(fetchTicketsForEvent.fulfilled, (state, action) => {
        if (action.payload) {
          const { eventId, tickets } = action.payload;
          state.tickets[eventId] = tickets;
        }
      })
      .addCase(fetchPromoCodesForEvent.pending, (state, { meta }) => {
        const { eventId } = meta.arg;
        state.eventsLoading[eventId] = true;
      })
      .addCase(fetchPromoCodesForEvent.fulfilled, (state, action) => {
        if (action.payload) {
          const { eventId, promoCodes } = action.payload;
          state.promoCodes[eventId] = promoCodes;
          state.eventsLoading[eventId] = false;
        }
      })
      .addCase(fetchAffiliatesForEvent.pending, (state, { meta }) => {
        const { eventId } = meta.arg;
        state.eventsLoading[eventId] = true;
      })
      .addCase(fetchAffiliatesForEvent.fulfilled, (state, action) => {
        if (action.payload) {
          const { eventId, affiliates } = action.payload;
          state.affiliates[eventId] = affiliates;
          state.eventsLoading[eventId] = false;
        }
      })
      .addCase(fetchSessionsForEvent.pending, (state, { meta }) => {
        const { eventId } = meta.arg;
        state.eventsLoading[eventId] = true;
      })
      .addCase(fetchSessionsForEvent.fulfilled, (state, action) => {
        if (action.payload) {
          const { eventId, sessions } = action.payload;
          state.sessions[eventId] = sessions;
          state.eventsLoading[eventId] = false;
        }
      });
  },
});

export const handleOrgChange = createAsyncThunk(
  "events/handleOrgChange",
  async (params, { getState, dispatch }) => {
    try {
      dispatch(resetEvents());
    } catch (e) {
      console.error("error getting events", e);
    }
  },
);

export const fetchEvents = createAsyncThunk(
  "events/fetchEvents",
  async (params, { getState, dispatch }) => {
    try {
      const usersState = usersSliceSelector(getState());
      const {
        user: { id: userId },
      } = usersState;
      const organizationsState = organizationsSliceSelector(getState());
      const { currentOrganization } = organizationsState;
      const { filter } = params || {};

      if (!currentOrganization) {
        throw new Error("no current organization");
      }

      let filterWhere = [];
      switch (filter) {
        case "upcoming":
          filterWhere = [where("endsAt", ">", Timestamp.now())];
          break;

        case "draft":
          filterWhere = [where("publishedAt", "==", null)];
          break;

        case "past":
          filterWhere = [where("endsAt", "<", Timestamp.now())];
          break;

        case "all":
          filterWhere = [];
          break;

        default:
          filterWhere = [];
      }

      const eventsRef = collection(database, "events");
      const q = query(
        eventsRef,
        and(
          or(
            where("scanners", "array-contains", userId),
            where("admins", "array-contains", userId),
          ),
          where("organizationId", "==", currentOrganization.id),
          ...filterWhere,
        ),
      );
      const querySnapshot = await getDocs(q);

      let events = querySnapshot.docs.reduce((acc, doc) => {
        const event = { id: doc.id, ...doc.data() };
        if (event.admins.includes(userId)) {
          dispatch(fetchTicketTypeStatsForEvent({ eventId: event.id }));
        }
        return {
          ...acc,
          [doc.id]: event,
        };
      }, {});

      const eventIds = Object.keys(events);
      let eventStats = {};

      if (eventIds.length > 0) {
        const chunkSize = 30;
        for (let i = 0; i < eventIds.length; i += chunkSize) {
          const chunk = eventIds.slice(i, i + chunkSize);
          const statsRef = collection(database, "eventStats");
          const statsQ = query(statsRef, where(documentId(), "in", chunk));
          const statsQuerySnapshot = await getDocs(statsQ);

          eventStats = statsQuerySnapshot.docs.reduce((acc, doc) => {
            const eventStat = { id: doc.id, ...doc.data() };
            return {
              ...acc,
              [doc.id]: eventStat,
            };
          }, eventStats);
        }
      }

      return { events: serialize(events), eventStats: serialize(eventStats) };
    } catch (e) {
      console.error("error getting events", e);
    }
  },
);

export const fetchEventById = createAsyncThunk(
  "events/fetchEventById",
  async (params, { getState, dispatch }) => {
    const usersState = usersSliceSelector(getState());
    const {
      user: { id: userId },
    } = usersState;

    const { eventId } = params;
    const event = await getValidatedDoc(doc(database, "events", eventId));
    let eventStats;
    if (event.admins.includes(userId)) {
      await dispatch(fetchTicketTypeStatsForEvent({ eventId }));
      const docRef = doc(database, "eventStats", eventId);
      const docSnap = await getDoc(docRef);
      eventStats = docSnap.data();
    }
    return {
      eventId,
      event: serialize(event),
      eventStats: serialize(eventStats),
    };
  },
);

export const fetchTicketTypeStatsForEvent = createAsyncThunk(
  "events/fetchTicketTypeStatsForEvent",
  async (params, { getState, dispatch }) => {
    const { eventId } = params;
    console.log("fetchTicketTypeStatsForEvent", eventId);
    try {
      const ref = collection(database, "events", eventId, "ticketTypeStats");
      const snapshot = await getDocs(ref);
      const ticketTypeStats = snapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });

      return { eventId, ticketTypeStats: serialize(ticketTypeStats) };
    } catch (e) {
      console.error("error getting events", e);
    }
  },
);

export const beginListeningToTickets = (eventId) => (dispatch) => {
  const q = query(collection(database, "events", eventId, "tickets"));

  return onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
    const tickets = [];
    snapshot.docChanges().forEach((change) => {
      const doc = change.doc;
      const ticket = doc.data();
      tickets.push({
        ...doc.data(),
        id: doc.id,
        hasPendingWrites: snapshot.metadata.hasPendingWrites,
        fromCache: snapshot.metadata.fromCache,
      });
    });
    dispatch(receiveTickets({ eventId, tickets: serialize(tickets) }));
  });
};

export const fetchTicketsForEvent = createAsyncThunk(
  "events/fetchTicketsForEvent",
  async (params, { getState, dispatch }) => {
    const { eventId } = params;
    console.log("fetchTicketsForEvent", eventId);
    try {
      const ref = collection(database, "events", eventId, "tickets");
      const snapshot = await getDocs(ref);
      const tickets = snapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });

      return { eventId, tickets: serialize(tickets) };
    } catch (e) {
      console.error("error getting events", e);
    }
  },
);

export const buildNewEvent = createAsyncThunk(
  "events/buildNewEvent",
  async (params, { getState, dispatch }) => {
    try {
      const usersState = usersSliceSelector(getState());
      const organizationsState = organizationsSliceSelector(getState());
      const { currentOrganization } = organizationsState;

      if (!currentOrganization) {
        throw new Error("no current organization");
      }

      const {
        user: { id },
      } = usersState || {};
      const newEvent = {
        createdBy: id,
        admins: [id],
        scanners: [],
        organizationId: currentOrganization.id,
        publishedAt: null,
        capacity: null,
        showId: null,
        roomId: null,
        promoCodeEntryVisible: false,
        sendQrCodes: true,
      };
      return { newEvent };
    } catch (e) {
      console.error("error creating event", e);
      return {};
    }
  },
);

export const createEvent = createAsyncThunk(
  "events/createEvent",
  async (params, { getState, dispatch }) => {
    const { values } = params;
    try {
      const event = newEventSelector(getState());
      const newEventValues = { ...event, ...values };
      const collectionRef = collection(database, "events");
      const docRef = await addDoc(collectionRef, newEventValues);
      const newEvent = await getValidatedDoc(docRef);
      return { newEvent: serialize({ ...newEvent, id: docRef.id }) };
    } catch (e) {
      console.error("error creating event", e);
    }
  },
);

export const duplicateEvent = createAsyncThunk(
  "events/duplicateEvent",
  async (params, { getState, dispatch }) => {
    const { eventId } = params;
    try {
      const duplicateEventFunction = httpsCallable(functions, "duplicateEvent");
      const result = await duplicateEventFunction({
        eventId,
      });
      const { eventId: newEventId } = parseFunctionsResponse(result.data);
      const newEvent = await getValidatedDoc(
        doc(database, "events", newEventId),
      );
      let eventStats;
      await dispatch(fetchTicketTypeStatsForEvent({ eventId }));
      const docRef = doc(database, "eventStats", newEventId);
      const docSnap = await getDoc(docRef);
      eventStats = docSnap.data();
      return {
        newEvent: serialize({
          ...newEvent,
          id: newEventId,
          eventStats: eventStats,
        }),
      };
    } catch (e) {
      console.error("error duplicating event", e);
    }
  },
);

export const updateEvent = createAsyncThunk(
  "events/updateEvent",
  async (params, { getState, dispatch }) => {
    const { eventId, values } = params;
    const usersState = usersSliceSelector(getState());
    const {
      user: { id: userId },
    } = usersState;
    try {
      console.log("updating event", values);
      const docRef = doc(database, "events", eventId);
      await updateDoc(docRef, { ...values, validated: false });
      const event = await getValidatedDoc(docRef);
      console.log("done updating event");
      if (event.admins.includes(userId)) {
        dispatch(fetchTicketTypeStatsForEvent({ eventId: event.id }));
      }
      return { eventId, event: serialize(event) };
    } catch (e) {
      console.error("error updating event", e);
    }
  },
);

export const updateEventStats = createAsyncThunk(
  "events/updateEventStats",
  async (params, { getState, dispatch }) => {
    const { eventId, values } = params;
    const usersState = usersSliceSelector(getState());
    const {
      user: { id: userId },
    } = usersState;
    try {
      console.log("updating event stats", values);
      const docRef = doc(database, "eventStats", eventId);
      const result = await updateDoc(docRef, { ...values });
      console.log("done updating event stats");
      const docSnap = await getDoc(docRef);
      const eventStats = docSnap.data();

      return { eventId, eventStats: serialize(eventStats) };
    } catch (e) {
      console.error("error updating event", e);
    }
  },
);

export const setEventPublished = createAsyncThunk(
  "events/publishEvent",
  async (params, { getState, dispatch }) => {
    const { eventId, published } = params;
    try {
      console.log("publishing event");
      const publishEventFunction = httpsCallable(
        functions,
        "setEventPublished",
      );
      const result = await publishEventFunction({
        eventId,
        published,
      });
      const { event } = parseFunctionsResponse(result.data);
      return { eventId, event: serialize(event) };
    } catch (e) {
      console.error("error publishing event", e);
    }
  },
);

export const createTicketType = createAsyncThunk(
  "events/createTicketType",
  async (params, { getState, dispatch }) => {
    const { eventId, values } = params;
    console.log("creating ticket type", params);
    try {
      const docRef = doc(database, "events", eventId);
      const event = await getValidatedDoc(docRef);
      const ticketTypes = event.ticketTypes || [];
      let newTTs;
      if (values instanceof Array) {
        newTTs = values;
      } else {
        newTTs = [values];
      }
      const updatePayload = { ticketTypes: [...ticketTypes, ...newTTs] };

      await dispatch(updateEvent({ eventId, values: updatePayload }));
      await Promise.all(
        newTTs.map(async ({ key }) => {
          const ticketTypeStatRef = doc(
            database,
            "events",
            eventId,
            "ticketTypeStats",
            key,
          );
          await awaitDocCreation(ticketTypeStatRef);
        }),
      );
      const eventAfter = await getValidatedDoc(docRef);
      await dispatch(setEvent({ eventId, event: serialize(eventAfter) }));
      console.log("done creating ticket type");
    } catch (e) {
      console.error("error creating ticket types", e);
      await dispatch(setEventLoading({ eventId, loading: false }));
      await dispatch(
        setEventUpdateError({ eventId, error: "error creating ticket type" }),
      );
    }
  },
);

export const importTicketType = createAsyncThunk(
  "events/importTicketType",
  async (params, { getState, dispatch }) => {
    const { eventId, ticketTypeHistoryItem } = params;

    const docRef = doc(database, "events", eventId);
    const event = await getValidatedDoc(docRef);

    const { orderFormFields, ticketTypes, startsAt, timezone } = event;

    const {
      key,
      name,
      description,
      usdCents,
      stats,
      availabilityStartsAtOffsetFromEventDays,
      endsAtOffsetMillis,
      availabilityStartsAtTimeOfDay: { hours, minutes },
      orderFormFields: importedOrderFormFields,
    } = ticketTypeHistoryItem;

    const zonedStartsAt = utcToZonedTime(startsAt.toDate(), timezone);
    let availabilityStartsAt = startOfDay(zonedStartsAt);
    availabilityStartsAt = addDays(
      availabilityStartsAt,
      -availabilityStartsAtOffsetFromEventDays,
    );
    availabilityStartsAt = setHours(availabilityStartsAt, hours);
    availabilityStartsAt = setMinutes(availabilityStartsAt, minutes);

    const availabilityEndsAt = addMilliseconds(
      availabilityStartsAt,
      endsAtOffsetMillis,
    );

    const ticketType = {
      key: uuidv4(),
      importKey: key,
      // key,
      name,
      description,
      usdCents,
      availabilityEndsAt,
      availabilityStartsAt,
      stats,
    };
    const existingOrderFormFieldKeys = orderFormFields.map(({ key }) => key);
    const importedOrderFormFieldKeys = importedOrderFormFields.map(
      ({ key }) => key,
    );

    const updatedOrderFormFields = orderFormFields.map((orderFormField) => {
      const { key: existingKey, collectForTicketTypes } = orderFormField;
      if (importedOrderFormFieldKeys.includes(existingKey)) {
        return {
          ...orderFormField,
          collectForTicketTypes: [...collectForTicketTypes, key],
        };
      } else {
        return orderFormField;
      }
    });

    const newOrderFormFields = importedOrderFormFields
      .filter(({ key }) => !existingOrderFormFieldKeys.includes(key))
      .map((newOrderFormField) => {
        return {
          ...newOrderFormField,
          collectEveryOrder: false,
          collectForTicketTypes: [key],
        };
      });

    const updatePayload = {
      ticketTypes: [...(ticketTypes || []), ticketType],
      orderFormFields: [...updatedOrderFormFields, ...newOrderFormFields],
    };

    const ticketTypeStatRef = doc(
      database,
      "events",
      eventId,
      "ticketTypeStats",
      key,
    );

    await dispatch(updateEvent({ eventId, values: updatePayload }));
    await awaitDocCreation(ticketTypeStatRef);
    const eventAfter = await getValidatedDoc(docRef);
    await dispatch(setEvent({ eventId, event: serialize(eventAfter) }));
  },
);

export const updateTicketType = createAsyncThunk(
  "events/updateTicketType",
  async (params, { getState, dispatch }) => {
    const { eventId, values } = params;
    let event;
    try {
      const docRef = doc(database, "events", eventId);
      event = await getValidatedDoc(docRef);
      const ticketTypes = event.ticketTypes || [];
      let updated = 0;
      const updatedTicketTypes = ticketTypes.map((ticketType) => {
        if (ticketType.key == values.key) {
          updated += 1;
          return values;
        } else {
          return ticketType;
        }
      });
      if (updated !== 1) {
        throw new Error("ticket type not found");
      }
      const updatePayload = { ticketTypes: updatedTicketTypes };
      await dispatch(updateEvent({ eventId, values: updatePayload }));
    } catch (e) {
      console.error("error updating ticket type", e);
      await dispatch(setEvent({ eventId, event: serialize(event) }));
      await dispatch(setEventLoading({ eventId, loading: false }));
      await dispatch(
        setEventUpdateError({ eventId, error: "error updating ticket type" }),
      );
    }
  },
);

export const deleteTicketType = createAsyncThunk(
  "events/deleteTicketType",
  async (params, { getState, dispatch }) => {
    const { eventId, key } = params;

    let event;
    try {
      const docRef = doc(database, "events", eventId);
      event = await getValidatedDoc(docRef);
      const ticketTypes = event.ticketTypes || [];
      const updatedTicketTypes = ticketTypes.filter((tt) => {
        return tt.key !== key;
      });
      if (ticketTypes.length !== updatedTicketTypes.length + 1) {
        throw new Error("ticket type not found");
      }

      const orderFormFields = event.orderFormFields || [];
      const updatedOrderFormFields = orderFormFields.map((orderFormField) => {
        return {
          ...orderFormField,
          collectForTicketTypes: (
            orderFormField.collectForTicketTypes || []
          ).filter((collectForKey) => collectForKey !== key),
        };
      });

      const updatePayload = {
        ticketTypes: updatedTicketTypes,
        orderFormFields: updatedOrderFormFields,
      };
      await dispatch(updateEvent({ eventId, values: updatePayload }));
    } catch (e) {
      console.error("error updating ticket type", e);
      await dispatch(setEvent({ eventId, event: serialize(event) }));
      await dispatch(setEventLoading({ eventId, loading: false }));
      await dispatch(
        setEventUpdateError({
          eventId,
          error: "error updating ticket type",
        }),
      );
    }
  },
);

export const addPerformerToEvent = createAsyncThunk(
  "events/addPerformerToEvent",
  async (params, { getState, dispatch }) => {
    const { performerName, eventId } = params;
    console.log("adding performer to event", params);

    try {
      const docRef = doc(database, "events", eventId);
      const event = await getValidatedDoc(docRef);
      const existingPerformers = event.performers || [];

      const updatePayload = {
        performers: [...existingPerformers, { name: performerName }],
      };

      await dispatch(updateEvent({ eventId, values: updatePayload }));
      const eventAfter = await getValidatedDoc(docRef);
      await dispatch(setEvent({ eventId, event: serialize(eventAfter) }));
      console.log("done adding performer to event");
    } catch (e) {
      console.error("error creating ticket types", e);
      await dispatch(setEventLoading({ eventId, loading: false }));
      await dispatch(
        setEventUpdateError({ eventId, error: "error creating ticket type" }),
      );
    }
  },
);

export const removePerformerFromEvent = createAsyncThunk(
  "events/removePerformerFromEvent",
  async (params, { getState, dispatch }) => {
    const { eventId, name: deletedName } = params;

    let event;
    try {
      const docRef = doc(database, "events", eventId);
      event = await getValidatedDoc(docRef);
      const performers = event.performers || [];
      const updatedPerformers = performers.filter(
        ({ name }) => name !== deletedName,
      );
      const updatePayload = {
        performers: updatedPerformers,
      };
      await dispatch(updateEvent({ eventId, values: updatePayload }));
    } catch (e) {
      console.error("error removing performer", e);
      await dispatch(setEvent({ eventId, event: serialize(event) }));
      await dispatch(setEventLoading({ eventId, loading: false }));
      await dispatch(
        setEventUpdateError({
          eventId,
          error: "error updating performer",
        }),
      );
    }
  },
);

export const reorderTicketTypes = createAsyncThunk(
  "events/reorderTicketTypes",
  async (params, { getState, dispatch }) => {
    const { eventId, newKeysList } = params;
    let event;
    try {
      const orderMap = newKeysList.reduce((acc, key, index) => {
        return { ...acc, [key]: index };
      }, {});

      // temp update state with new order to prevent UI from flashing items in wrong order
      const stateEvent = { ...getState().adminEvents.events[eventId] };
      stateEvent.ticketTypes = sortBy(stateEvent.ticketTypes, (ticketType) => {
        const sortOrder = orderMap[ticketType.key];
        return sortOrder;
      });
      await dispatch(setEvent({ eventId, event: stateEvent }));

      const docRef = doc(database, "events", eventId);
      event = await getValidatedDoc(docRef);
      const ticketTypes = event.ticketTypes || [];

      if (newKeysList.length !== ticketTypes.length) {
        throw new Error("unexpected sort order");
      }

      const sortedTicketTypes = sortBy(ticketTypes, (ticketType) => {
        const sortOrder = orderMap[ticketType.key];
        if (sortOrder == undefined) {
          throw new Error("unexpected sort order");
        }
        return sortOrder;
      });

      const updatePayload = { ticketTypes: sortedTicketTypes };
      await dispatch(updateEvent({ eventId, values: updatePayload }));
    } catch (e) {
      console.error("error reordering ticket types", e);
      await dispatch(setEvent({ eventId, event: serialize(event) }));
      await dispatch(setEventLoading({ eventId, loading: false }));
      await dispatch(
        setEventUpdateError({ eventId, error: "error reordering" }),
      );
    }
  },
);

export const createOrderFormField = createAsyncThunk(
  "events/createOrderFormField",
  async (params, { getState, dispatch }) => {
    console.log("creating order form field");
    const { eventId, values } = params;
    try {
      const docRef = doc(database, "events", eventId);
      const event = await getValidatedDoc(docRef);
      const orderFormFields = event.orderFormFields || [];
      let newOFFs;
      if (values instanceof Array) {
        newOFFs = values;
      } else {
        newOFFs = [values];
      }
      const updatePayload = {
        orderFormFields: [...orderFormFields, ...newOFFs],
      };
      await dispatch(updateEvent({ eventId, values: updatePayload }));
      console.log("done creating order form field");
    } catch (e) {
      console.error("error creating order form field", e);
      await dispatch(setEventLoading({ eventId, loading: false }));
      await dispatch(
        setEventUpdateError({
          eventId,
          error: "error creating order form field",
        }),
      );
    }
  },
);

export const updateOrderFormField = createAsyncThunk(
  "events/updateOrderFormField",
  async (params, { getState, dispatch }) => {
    console.log("updating order form field", params);
    const { eventId, values } = params;

    let event;
    try {
      const docRef = doc(database, "events", eventId);
      event = await getValidatedDoc(docRef);
      const orderFormFields = event.orderFormFields || [];
      let updated = 0;
      let updatedValues = [];
      if (values instanceof Array) {
        updatedValues = values;
      } else {
        updatedValues = [values];
      }

      const updatedOrderFormFields = orderFormFields.map((field) => {
        const foundUpdate = updatedValues.find(({ key }) => key === field.key);
        if (Boolean(foundUpdate)) {
          updated += 1;
          return foundUpdate;
        } else {
          return field;
        }
      });
      if (updated !== updatedValues.length) {
        throw new Error("order form field not found", [
          updated,
          updatedValues.length,
        ]);
      }
      const updatePayload = { orderFormFields: updatedOrderFormFields };
      console.log("done updating order form field");
      await dispatch(updateEvent({ eventId, values: updatePayload }));
    } catch (e) {
      console.error("error updating order form field", e);
      await dispatch(setEvent({ eventId, event: serialize(event) }));
      await dispatch(setEventLoading({ eventId, loading: false }));
      await dispatch(
        setEventUpdateError({
          eventId,
          error: "error updating order form field",
        }),
      );
    }
  },
);

export const deleteOrderFormField = createAsyncThunk(
  "events/deleteOrderFormField",
  async (params, { getState, dispatch }) => {
    const { eventId, key } = params;

    let event;
    try {
      const docRef = doc(database, "events", eventId);
      event = await getValidatedDoc(docRef);
      const orderFormFields = event.orderFormFields || [];
      const updatedOrderFormFields = orderFormFields.filter((field) => {
        return field.key !== key;
      });
      if (orderFormFields.length !== updatedOrderFormFields.length + 1) {
        throw new Error("order form field not found");
      }
      const updatePayload = { orderFormFields: updatedOrderFormFields };
      await dispatch(updateEvent({ eventId, values: updatePayload }));
    } catch (e) {
      console.error("error updating order form field", e);
      await dispatch(setEvent({ eventId, event: serialize(event) }));
      await dispatch(setEventLoading({ eventId, loading: false }));
      await dispatch(
        setEventUpdateError({
          eventId,
          error: "error updating order form field",
        }),
      );
    }
  },
);

export const reorderOrderFormFields = createAsyncThunk(
  "events/reorderOrderFormFields",
  async (params, { getState, dispatch }) => {
    const { eventId, newKeysList } = params;
    let event;
    try {
      const orderMap = newKeysList.reduce((acc, key, index) => {
        return { ...acc, [key]: index };
      }, {});

      // temp update state with new order to prevent UI from flashing items in wrong order
      const stateEvent = { ...getState().adminEvents.events[eventId] };
      stateEvent.orderFormFields = sortBy(
        stateEvent.orderFormFields,
        (orderFormField) => {
          const sortOrder = orderMap[orderFormField.key];
          return sortOrder;
        },
      );
      await dispatch(setEvent({ eventId, event: stateEvent }));

      const docRef = doc(database, "events", eventId);
      event = await getValidatedDoc(docRef);
      const orderFormFields = event.orderFormFields || [];

      if (newKeysList.length !== orderFormFields.length) {
        throw new Error("unexpected sort order");
      }

      const sortedOrderFormFields = sortBy(
        orderFormFields,
        (orderFormField) => {
          const sortOrder = orderMap[orderFormField.key];
          if (sortOrder == undefined) {
            throw new Error("unexpected sort order");
          }
          return sortOrder;
        },
      );

      const updatePayload = { orderFormFields: sortedOrderFormFields };
      await dispatch(updateEvent({ eventId, values: updatePayload }));
    } catch (e) {
      console.error("error reordering order form fields", e);
      await dispatch(setEvent({ eventId, event: serialize(event) }));
      await dispatch(setEventLoading({ eventId, loading: false }));
      await dispatch(
        setEventUpdateError({ eventId, error: "error reordering" }),
      );
    }
  },
);

export const createBannerImage = createAsyncThunk(
  "events/createBannerImage",
  async (params, { getState, dispatch }) => {
    const { eventId, filename, file } = params;

    try {
      const storageRef = firebaseStorageRef(
        storage,
        `images/events/${eventId}/banners/${filename}`,
      );

      const snapshot = await uploadBytes(storageRef, file);
      const downloadUrl = await getDownloadURL(snapshot.ref);
      const docRef = doc(database, "events", eventId);
      const event = await getValidatedDoc(docRef);
      const banners = event.banners || [];
      const updatePayload = {
        banners: [...banners, { key: filename, src: downloadUrl }],
      };
      await dispatch(updateEvent({ eventId, values: updatePayload }));
    } catch (e) {
      console.error("error creating banner", e);
      await dispatch(setEventLoading({ eventId, loading: false }));
      await dispatch(
        setEventUpdateError({
          eventId,
          error: "error creating banner",
        }),
      );
    }
  },
);

export const reorderBannerImages = createAsyncThunk(
  "events/reorderBannerImages",
  async (params, { getState, dispatch }) => {
    const { eventId, newKeysList } = params;
    let event;
    try {
      const orderMap = newKeysList.reduce((acc, key, index) => {
        return { ...acc, [key]: index };
      }, {});

      // temp update state with new order to prevent UI from flashing items in wrong order
      const stateEvent = { ...getState().adminEvents.events[eventId] };
      stateEvent.banners = sortBy(stateEvent.banners, (banner) => {
        const sortOrder = orderMap[banner.key];
        return sortOrder;
      });

      await dispatch(setEvent({ eventId, event: stateEvent }));

      const docRef = doc(database, "events", eventId);
      event = await getValidatedDoc(docRef);
      const banners = event.banners || [];

      if (newKeysList.length !== banners.length) {
        throw new Error("unexpected sort order");
      }

      const sortedBanners = sortBy(banners, (banner) => {
        const sortOrder = orderMap[banner.key];
        if (sortOrder == undefined) {
          throw new Error("unexpected sort order");
        }
        return sortOrder;
      });

      const updatePayload = { banners: sortedBanners };
      await dispatch(updateEvent({ eventId, values: updatePayload }));
    } catch (e) {
      console.error("error reordering banners", e);
      await dispatch(setEvent({ eventId, event: serialize(event) }));
      await dispatch(setEventLoading({ eventId, loading: false }));
      await dispatch(
        setEventUpdateError({ eventId, error: "error reordering" }),
      );
    }
  },
);

export const deleteBannerImage = createAsyncThunk(
  "events/deleteBannerImage",
  async (params, { getState, dispatch }) => {
    const { eventId, key } = params;

    try {
      const storageRef = firebaseStorageRef(
        storage,
        `images/events/${eventId}/banners/${key}`,
      );
      await deleteObject(storageRef);
      const docRef = doc(database, "events", eventId);
      const event = await getValidatedDoc(docRef);
      const banners = event.banners || [];
      const filteredBanners = banners.filter((banner) => {
        return banner.key !== key;
      });
      const updatePayload = {
        banners: filteredBanners,
      };
      await dispatch(updateEvent({ eventId, values: updatePayload }));
    } catch (e) {
      console.error("error creating banner", e);
      await dispatch(setEventLoading({ eventId, loading: false }));
      await dispatch(
        setEventUpdateError({
          eventId,
          error: "error creating banner",
        }),
      );
    }
  },
);

export const sendTicketEmailToAttendees = createAsyncThunk(
  "organizations/sendTicketEmailToAttendees",
  async (params, { getState, dispatch }) => {
    const { ticketIds, eventId } = params;
    console.log("sending ticket email to attendee", { ticketIds, eventId });
    try {
      const callableFunction = httpsCallable(
        functions,
        "sendTicketEmailToAttendees",
      );
      const result = await callableFunction({
        eventId,
        ticketIds,
      });
      return { eventId };
    } catch (e) {
      console.error("error fetching orders", e);
    }
  },
);

export const sendOrderConfirmationEmail = createAsyncThunk(
  "organizations/resendOrderConfirmationEmail",
  async (params, { getState, dispatch }) => {
    const { orderId, organizationId, eventId, sendAttendees } = params;
    console.log("resending email confirmation for order", orderId);
    try {
      const callableFunction = httpsCallable(
        functions,
        "resendOrderConfirmationEmail",
      );
      const result = await callableFunction({
        orderId,
        organizationId,
        sendAttendees,
      });
      return { eventId };
    } catch (e) {
      console.error("error fetching orders", e);
    }
  },
);

export const fetchOrdersForEvent = createAsyncThunk(
  "organizations/fetchOrdersForEvent",
  async (params, { getState, dispatch }) => {
    const { eventId, organizationId } = params;
    console.log("fetching orders for event", eventId);
    try {
      const ordersRef = collection(
        database,
        "organizations",
        organizationId,
        "orders",
      );
      const eventRef = doc(database, "events", eventId);
      const q = query(ordersRef, where("event", "==", eventRef));
      const querySnapshot = await getDocs(q);
      const orders = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data(), ref: doc.ref };
      });
      return { eventId, orders: serialize(orders) };
    } catch (e) {
      console.error("error fetching orders", e);
    }
  },
);

export const checkInTicket = createAsyncThunk(
  "events/checkInTicket",
  async (params, { getState, dispatch }) => {
    const { eventId, sessionId, ticketId, checkedIn } = params;
    console.log("checking in ticket", params);
    const usersState = usersSliceSelector(getState());
    const ticketsState = ticketsSelector(getState());
    const ticketsForEvent = ticketsState[eventId];
    const ticket = ticketsForEvent.find(({ id }) => id === ticketId);
    const { sessionCheckIns } = ticket;
    const {
      user: { id: userId },
    } = usersState;
    const checkIn = checkedIn
      ? { by: userId, createdAt: Timestamp.now() }
      : null;

    const docRef = doc(database, "events", eventId, "tickets", ticketId);
    if (sessionId) {
      await updateDoc(docRef, {
        sessionCheckIns: { ...sessionCheckIns, [sessionId]: checkIn },
      });
    } else {
      await updateDoc(docRef, { checkIn });
    }

    return { eventId, ticketId, checkedIn };
  },
);

export const updateBadgeForTicket = createAsyncThunk(
  "events/updateBadgeForTicket",
  async (params, { getState, dispatch }) => {
    const { eventId, ticketId, serialNumber } = params;
    console.log("updating badge for ticket", params);
    const usersState = usersSliceSelector(getState());
    const {
      user: { id: userId },
    } = usersState;
    const docRef = doc(database, "events", eventId, "tickets", ticketId);
    let badge;
    if (serialNumber) {
      badge = {
        programmedBy: userId,
        programmedAt: Timestamp.now(),
        serialNumber,
      };
    } else {
      badge = null;
    }

    await updateDoc(docRef, { badge });

    return { eventId, ticketId, serialNumber };
  },
);

export const createTicket = createAsyncThunk(
  "organizations/createTicket",
  async (params, { getState, dispatch }) => {
    const {
      eventId,
      ticketType: ticketTypeKey,
      firstName,
      lastName,
      email,
    } = params;
    console.log("creating ticket");
    const event = eventsSelector(getState())[eventId];
    const ticketType = event.ticketTypes.find(
      ({ key }) => key == ticketTypeKey,
    );
    const { name: ticketTypeName, description } = ticketType;
    try {
      const newTicket = {
        checkIn: null,
        createdAt: Timestamp.now(),
        description,
        name: ticketTypeName,
        order: null,
        ticketTypeKey,
        orderFormFields: [
          { name: "First Name", key: "firstName", value: firstName },
          { name: "Last Name", key: "lastName", value: lastName },
          { name: "Email", key: "email", value: email },
        ],
        purchaserEmail: email,
        purchaserFirstName: firstName,
        purchaserLastName: lastName,
      };
      const collectionRef = collection(database, "events", eventId, "tickets");
      const docRef = await addDoc(collectionRef, newTicket);
    } catch (e) {
      console.error("error fetching tickets", e);
    }
  },
);

const orderFormFieldValue = ({ type, value, orderFormField, ticket }) => {
  if (value === undefined) {
    return "<unanswered>";
  }
  switch (type) {
    case "smallText":
    case "largeText":
      return value;
    case "checkbox":
      return Boolean(value); // (changed this to bool instead of stringify) ? "true" : "false";
    case "multiSelectDropdown":
    case "multiSelectRadio":
      return {
        key: value,
        name: orderFormField.options.find(({ key }) => key === value)?.name,
      };
    case "multiSelectCheckbox":
      if (value && value.length > 0) {
        const valuesArray = value.map((valueItem) => {
          const option = orderFormField.options.find(
            ({ key }) => key === valueItem,
          );
          return {
            key: option?.key,
            name: option?.name,
          };
        });
        if (ticket) {
          return valuesArray;
        } else {
          return valuesArray.reduce((acc, valueObj, index) => {
            return { ...acc, [index]: valueObj };
          }, {});
        }
      } else {
        if (ticket) {
          return [];
        } else {
          return {};
        }
      }
  }
};

export const createOrder = createAsyncThunk(
  "organizations/createOrder",
  async (params, { getState, dispatch }) => {
    const usersState = usersSliceSelector(getState());
    const {
      user: { id: userId, email: userEmail, displayName },
    } = usersState;
    const { eventId, sendConfirmationEmail, sendAttendees, values } = params;
    console.log("creating order", {
      eventId,
      sendConfirmationEmail,
      sendAttendees,
      values,
    });
    try {
      const event = eventsSelector(getState())[eventId];
      const {
        name,
        orderFormFields: eventOrderFormFields,
        ticketTypes,
        organizationId,
      } = event;
      console.log("eventOrderFormFields", eventOrderFormFields);
      const source = {
        type: "manual_user",
        userId,
        email: userEmail,
        displayName,
      };
      const items = values.tickets.reduce((acc, ticketValues) => {
        const { price, ticketTypeKey } = ticketValues;
        const existing = acc.find(
          ({ ticketTypeKey: existingKey, usdCentsEach }) =>
            existingKey === ticketTypeKey && price === usdCentsEach,
        );

        if (existing) {
          const { quantity, orderFormFields, totalUsdCents } = existing;
          existing.quantity = quantity + 1;
          existing.orderFormFields = orderFormFields.map((orderFormField) => {
            const { values } = orderFormField;
            const newValue = ticketValues[orderFormField.key];
            return { ...orderFormField, values: [...values, newValue] };
          });
          existing.totalUsdCents = totalUsdCents + price;
          return acc;
        } else {
          const { name: ticketTypeName } = ticketTypes.find(
            ({ key }) => key === ticketTypeKey,
          );
          return [
            ...acc,
            {
              name: ticketTypeName,
              orderFormFields: eventOrderFormFields
                .filter(({ key }) => Object.keys(ticketValues).includes(key))
                .map((orderFormField) => {
                  const { key, name, type, internalOnly } = orderFormField;
                  return {
                    key,
                    name,
                    type,
                    internalOnly: internalOnly || null,
                    values: [
                      orderFormFieldValue({
                        orderFormField,
                        type,
                        value: ticketValues[key],
                        ticket: false,
                      }),
                    ],
                  };
                }),
              quantity: 1,
              ticketTypeKey,
              totalUsdCents: price || 0,
              usdCentsEach: price || 0,
            },
          ];
        }
      }, []);
      const orderFormFields = eventOrderFormFields
        .filter(({ key }) => Object.keys(values).includes(key))
        .map((orderFormField) => {
          const { key, name, type, internalOnly } = orderFormField;
          return {
            key,
            name,
            type,
            internalOnly: internalOnly || null,
            value: orderFormFieldValue({
              orderFormField,
              type,
              value: values[key],
              ticket: false,
            }),
          };
        });
      const totalUsdCents = items.reduce(
        (acc, { totalUsdCents }) => (totalUsdCents || 0) + acc,
        0,
      );
      const order = {
        createdAt: Timestamp.now(),
        source,
        event: doc(database, `events/${eventId}`),
        eventName: name,
        items,
        orderFormFields,
        totalUsdCents,
      };

      const ordersRef = collection(
        database,
        "organizations",
        organizationId,
        "orders",
      );
      console.log("order doc", order);
      const orderRef = await addDoc(ordersRef, order);

      const tickets = values.tickets.map((ticketValues) => {
        const { ticketTypeKey } = ticketValues;
        const ticketType = ticketTypes.find(({ key }) => key === ticketTypeKey);
        const { name, description } = ticketType;
        return {
          source,
          checkIn: null,
          createdAt: Timestamp.now(),
          description,
          name,
          order: orderRef,
          ticketTypeKey,
          orderFormFields: eventOrderFormFields
            .filter(({ key }) => Object.keys(ticketValues).includes(key))
            .map((orderFormField) => {
              const { key, name, type, internalOnly } = orderFormField;
              return {
                key,
                name,
                type,
                internalOnly: internalOnly || null,
                value: orderFormFieldValue({
                  orderFormField,
                  type,
                  value: ticketValues[key],
                  ticket: true,
                }),
              };
            }),
          purchaserOrderFormFields: orderFormFields,
        };
      });

      const ticketsRef = collection(database, "events", eventId, "tickets");
      const addedTickets = await Promise.all(
        tickets.map(async (ticket) => {
          console.log("adding ticket", ticket);
          return await addDoc(ticketsRef, ticket);
        }),
      );

      if (sendConfirmationEmail) {
        dispatch(
          sendOrderConfirmationEmail({
            orderId: orderRef.id,
            organizationId,
            sendAttendees,
          }),
        );
      } else if (sendAttendees) {
        const ticketIds = addedTickets.map((ticketDoc) => ticketDoc.id);
        dispatch(sendTicketEmailToAttendees({ eventId, ticketIds }));
      }

      dispatch(fetchTicketTypeStatsForEvent({ eventId }));
    } catch (e) {
      console.error("error creating order", e);
    }
  },
);

export const updateTicketOrderFormAnswer = createAsyncThunk(
  "organizations/updateTicketOrderFormAnswer",
  async (params, { getState, dispatch }) => {
    const { eventId, ticket, orderFormFieldKey, isAttendee, value } = params;
    const {
      orderFormFields,
      purchaserOrderFormFields,
      id: ticketId,
    } = { ...ticket };
    console.log("updateTicketOrderFormAnswer", params);
    try {
      const replaceField = (orderFormFields) => {
        return orderFormFields.map((orderFormField) => {
          const { key } = orderFormField;
          if (key === orderFormFieldKey) {
            return { ...orderFormField, value };
          } else {
            return { ...orderFormField };
          }
        });
      };

      let updatePayload;
      if (isAttendee) {
        const newOrderFormFields = replaceField(orderFormFields);
        updatePayload = { orderFormFields: newOrderFormFields };
      } else {
        const newOrderFormFields = replaceField(purchaserOrderFormFields);
        updatePayload = { purchaserOrderFormFields: newOrderFormFields };
      }

      console.log("updatePayload", updatePayload);

      const docRef = doc(database, "events", eventId, "tickets", ticketId);
      await updateDoc(docRef, updatePayload);
    } catch (e) {
      console.error("error fetching tickets", e);
      await dispatch(
        setEventUpdateError({
          eventId,
          error: "error updating order form value",
        }),
      );
    }
  },
);

export const fetchPromoCodesForEvent = createAsyncThunk(
  "organizations/fetchPromoCodesForEvent",
  async (params, { getState, dispatch }) => {
    const { eventId } = params;
    try {
      const ref = collection(database, "events", eventId, "promoCodes");
      const querySnapshot = await getDocs(ref);
      const promoCodes = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      return { eventId, promoCodes: serialize(promoCodes) };
    } catch (e) {
      console.error("error fetching promo codes", e);
    }
  },
);

export const createPromoCode = createAsyncThunk(
  "organizations/createPromoCode",
  async (params, { getState, dispatch }) => {
    const { eventId, values } = params;
    console.log("creating promo code");
    try {
      const collectionRef = collection(
        database,
        "events",
        eventId,
        "promoCodes",
      );
      await addDoc(collectionRef, values);
      await dispatch(fetchPromoCodesForEvent({ eventId }));
    } catch (e) {
      console.error("error creating promo code", e);
    }
  },
);

export const updatePromoCode = createAsyncThunk(
  "organizations/updatePromoCode",
  async (params, { getState, dispatch }) => {
    const { eventId, promoCodeId, values } = params;
    console.log("updating promo code");
    try {
      const docRef = doc(
        database,
        "events",
        eventId,
        "promoCodes",
        promoCodeId,
      );
      await updateDoc(docRef, values);
      await dispatch(fetchPromoCodesForEvent({ eventId }));
    } catch (e) {
      console.error("error updating promo code", e);
    }
  },
);

export const fetchAffiliatesForEvent = createAsyncThunk(
  "organizations/fetchAffiliatesForEvent",
  async (params, { getState, dispatch }) => {
    console.log("fetchAffiliatesForEvent");
    const { eventId } = params;
    try {
      const ref = collection(database, "events", eventId, "affiliates");
      const querySnapshot = await getDocs(ref);
      const affiliates = [];
      querySnapshot.docs.forEach((doc) => {
        affiliates.push({ id: doc.id, ...doc.data() });
      });
      return { eventId, affiliates: serialize(affiliates) };
    } catch (e) {
      console.error("error fetching affiliates", e);
    }
  },
);

export const createAffiliate = createAsyncThunk(
  "organizations/createAffiliate",
  async (params, { getState, dispatch }) => {
    const { eventId, values } = params;
    console.log("creating promo code");
    try {
      const collectionRef = collection(
        database,
        "events",
        eventId,
        "affiliates",
      );
      await addDoc(collectionRef, values);
      await dispatch(fetchAffiliatesForEvent({ eventId }));
    } catch (e) {
      console.error("error creating affiliate", e);
    }
  },
);

export const updateAffiliate = createAsyncThunk(
  "organizations/updateAffiliate",
  async (params, { getState, dispatch }) => {
    const { eventId, affiliateId, values } = params;
    console.log("updating promo code");
    try {
      const docRef = doc(
        database,
        "events",
        eventId,
        "affiliates",
        affiliateId,
      );
      await updateDoc(docRef, values);
      await dispatch(fetchAffiliatesForEvent({ eventId }));
    } catch (e) {
      console.error("error updating affiliate", e);
    }
  },
);

export const fetchSessionsForEvent = createAsyncThunk(
  "organizations/fetchSessionsForEvent",
  async (params, { getState, dispatch }) => {
    console.log("fetchSessionsForEvent");
    const { eventId } = params;
    try {
      const ref = collection(database, "events", eventId, "sessions");
      const q = query(ref, orderBy("startsAt"));
      const querySnapshot = await getDocs(q);
      const sessions = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      return { eventId, sessions: serialize(sessions) };
    } catch (e) {
      console.error("error fetching sessions", e);
    }
  },
);

export const createSession = createAsyncThunk(
  "organizations/createSession",
  async (params, { getState, dispatch }) => {
    const { eventId, values } = params;
    console.log("creating promo code");
    try {
      const collectionRef = collection(database, "events", eventId, "sessions");
      await addDoc(collectionRef, values);
      await dispatch(fetchSessionsForEvent({ eventId }));
    } catch (e) {
      console.error("error creating session", e);
    }
  },
);

export const updateSession = createAsyncThunk(
  "organizations/updateSession",
  async (params, { getState, dispatch }) => {
    const { eventId, sessionId, values } = params;
    console.log("updating session");
    try {
      const docRef = doc(database, "events", eventId, "sessions", sessionId);
      await updateDoc(docRef, values);
      await dispatch(fetchSessionsForEvent({ eventId }));
    } catch (e) {
      console.error("error updating session", e);
    }
  },
);

const newEventSelector = (state) => deserialize(state.adminEvents.newEvent);
const duplicatedEventSelector = (state) =>
  deserialize(state.adminEvents.duplicatedEvent);
const eventsSelector = (state) => deserialize(state.adminEvents.events);
const eventStatsSelector = (state) => deserialize(state.adminEvents.eventStats);
const ticketsSelector = (state) => deserialize(state.adminEvents.tickets);
const eventsLoadingSelector = (state) => state.adminEvents.eventsLoading;
const duplicatingEventSelector = (state) => state.adminEvents.duplicatingEvent;
const eventsListLoadingSelector = (state) =>
  state.adminEvents.eventsListLoading;
const eventsListFetchedSelector = (state) =>
  state.adminEvents.eventsListFetched;
const eventsErrorSelector = (state) => state.adminEvents.eventUpdateErrors;
const ticketsCheckingInSelector = (state) =>
  state.adminEvents.ticketsCheckingIn;
const ticketsCheckingOutSelector = (state) =>
  state.adminEvents.ticketsCheckingOut;
const ticketTypeStatsSelector = (state) =>
  deserialize(state.adminEvents.ticketTypeStats);
const eventOrdersSelector = (state) => deserialize(state.adminEvents.orders);
const promoCodesSelector = (state) => deserialize(state.adminEvents.promoCodes);
const affiliatesSelector = (state) => deserialize(state.adminEvents.affiliates);
const sessionsSelector = (state) => deserialize(state.adminEvents.sessions);

const sessionsWithStatsSelector = createSelector(
  [sessionsSelector, ticketsSelector, eventsSelector],
  (sessions, tickets, events) => {
    return Object.keys(sessions).reduce((acc, eventId) => {
      const event = events[eventId];
      const { ticketTypes: eventTicketTypes } = event || {};
      const sessionsForEvent = sessions[eventId] || [];
      const ticketsForEvent = tickets[eventId] || [];

      return {
        ...acc,
        [eventId]: sessionsForEvent.map((session) => {
          const { ticketTypeSelection, ticketTypes, id: sessionId } = session;
          const ticketsForSession =
            ticketTypeSelection == "all"
              ? ticketsForEvent
              : ticketsForEvent.filter(({ ticketTypeKey }) =>
                  ticketTypes.includes(ticketTypeKey),
                );

          const totalTickets = ticketsForSession.length;
          const totalTicketsCheckedIn = ticketsForSession.filter(
            ({ sessionCheckIns }) =>
              Boolean(sessionCheckIns) && Boolean(sessionCheckIns[sessionId]),
          ).length;

          const ticketTypeStats = (eventTicketTypes || []).reduce(
            (acc, ticketType) => {
              const { key: ticketTypeId } = ticketType;
              const ticketsForTicketTypeForSession = ticketsForSession.filter(
                ({ ticketTypeKey }) => ticketTypeKey === ticketTypeId,
              );
              const totalTickets = ticketsForTicketTypeForSession.length;
              const totalTicketsCheckedIn =
                ticketsForTicketTypeForSession.filter(
                  ({ sessionCheckIns }) =>
                    Boolean(sessionCheckIns) &&
                    Boolean(sessionCheckIns[sessionId]),
                ).length;

              return {
                ...acc,
                [ticketTypeId]: { totalTickets, totalTicketsCheckedIn },
              };
            },
            {},
          );

          return {
            ...session,
            totalTickets,
            totalTicketsCheckedIn,
            ticketTypeStats,
          };
        }),
      };
    }, {});
  },
);

const sortedEventOrdersSelector = createSelector(
  [eventOrdersSelector],
  (orders) => {
    return Object.keys(orders).reduce((acc, eventId) => {
      const ordersForEvent = orders[eventId];
      if (!ordersForEvent) {
        return acc;
      }
      return {
        ...acc,
        [eventId]: sortBy(ordersForEvent, ({ createdAt }) => {
          return -createdAt.toMillis();
        }),
      };
    }, {});
  },
);

const ticketsSortedSelector = createSelector(
  [
    ticketsSelector,
    ticketsCheckingInSelector,
    ticketsCheckingOutSelector,
    eventsSelector,
  ],
  (tickets, ticketsCheckingIn, ticketsCheckingOut, events) => {
    return Object.keys(events).reduce((acc, eventId) => {
      const ticketsForEvent = tickets[eventId];
      if (!ticketsForEvent) {
        return acc;
      }

      const { ticketTypes, orderFormFields: eventOrderFormFields } =
        events[eventId];
      const ticketsForEventWithExtras = ticketsForEvent.map((ticket) => {
        const {
          id,
          orderFormFields,
          purchaserOrderFormFields,
          purchaserName,
          purchaserFirstName,
          purchaserLastName,
          purchaserEmail,
          ticketTypeKey,
          order,
        } = ticket;

        let missingPurchaserOrderFormFields;
        if (Boolean(order)) {
          missingPurchaserOrderFormFields = (eventOrderFormFields || [])
            .filter((eventOrderFormField) => {
              const { key: eventOrderFormFieldKey, collectEveryOrder } =
                eventOrderFormField;
              return (
                collectEveryOrder &&
                !(purchaserOrderFormFields || [])
                  .map(({ key }) => key)
                  .includes(eventOrderFormFieldKey)
              );
            })
            .map(({ name, key, type, internalOnly }) => {
              return { name, key, type, internalOnly };
            });
        } else {
          missingPurchaserOrderFormFields = [];
        }

        const missingOrderFormFields = (eventOrderFormFields || [])
          .filter((eventOrderFormField) => {
            const { key: eventOrderFormFieldKey, collectForTicketTypes } =
              eventOrderFormField;
            return (
              (collectForTicketTypes || []).includes(ticketTypeKey) &&
              !(orderFormFields || [])
                .map(({ key }) => key)
                .includes(eventOrderFormFieldKey)
            );
          })
          .map(({ name, key, type, internalOnly }) => {
            return { name, key, type, internalOnly };
          });

        let attendeeName;
        const ticketNameField = orderFormFields.find(
          ({ key }) => key == "name",
        );

        const ticketName1Field = orderFormFields.find(
          ({ key }) => key == "firstName",
        );

        const ticketName2Field = orderFormFields.find(
          ({ key }) => key == "lastName",
        );

        const ticketZipcodeField = orderFormFields.find(
          ({ key }) => key == "zipcode",
        );

        let sortName;
        if (ticketName1Field && ticketName2Field) {
          sortName = `${ticketName2Field.value} ${ticketName1Field.value}`;
        } else if (purchaserLastName && purchaserFirstName) {
          sortName = `${purchaserLastName} ${purchaserFirstName}`;
        } else {
          sortName = attendeeName;
        }

        sortName = sortName?.toLocaleLowerCase() || "";

        if (ticketName1Field && ticketName2Field) {
          attendeeName = `${ticketName1Field.value} ${ticketName2Field.value}`;
        } else if (ticketNameField) {
          attendeeName = ticketNameField.value;
        }

        let purchaserFullName;
        if (purchaserFirstName && purchaserLastName) {
          purchaserFullName = `${purchaserFirstName} ${purchaserLastName}`;
        } else if (purchaserName) {
          purchaserFullName = purchaserName;
        }
        const displayName = attendeeName || purchaserFullName;

        const ticketEmailField = orderFormFields.find(
          ({ key }) => key == "email",
        );
        const attendeeEmail = ticketEmailField?.value;
        const displayEmail = ticketEmailField?.value || purchaserEmail;
        const displayZipcode = ticketZipcodeField?.value || "";

        const ticketType = ticketTypes.find(({ key }) => key === ticketTypeKey);

        return {
          ...ticket,
          purchaserOrderFormFieldsOriginal: purchaserOrderFormFields || [],
          purchaserOrderFormFields: [
            ...(purchaserOrderFormFields || []),
            ...(missingPurchaserOrderFormFields || []),
          ],
          orderFormFieldsOriginal: orderFormFields,
          orderFormFields: [...orderFormFields, ...missingOrderFormFields],
          displayName,
          sortName,
          displayEmail,
          displayZipcode,
          attendeeName,
          attendeeEmail,
          ticketType,
          checkingIn: Boolean(ticketsCheckingIn[id]),
          checkingOut: Boolean(ticketsCheckingOut[id]),
        };
      });

      var sortedTickets = chain(ticketsForEventWithExtras)
        .sortBy((ticket) => {
          return ticket.id;
        })
        .sortBy((ticket) => {
          return ticket.sortName;
        })
        .value();

      return {
        ...acc,
        [eventId]: sortedTickets,
      };
    }, {});
  },
);

const newEventWithLoading = createSelector(
  [newEventSelector, eventsLoadingSelector],
  (newEvent, eventsLoading) => {
    if (newEvent) {
      return { ...newEvent, loading: eventsLoading.new };
    }
  },
);

const duplicatedEventWithLoading = createSelector(
  [duplicatedEventSelector, eventsLoadingSelector],
  (duplicatedEvent, eventsLoading) => {
    if (duplicatedEvent) {
      return { ...duplicatedEvent, loading: eventsLoading.new };
    }
  },
);

const eventsWithExtrasSelector = createSelector(
  [
    eventsSelector,
    eventStatsSelector,
    sortedEventOrdersSelector,
    eventsLoadingSelector,
    eventsErrorSelector,
    usersSliceSelector,
    promoCodesSelector,
    affiliatesSelector,
    sessionsWithStatsSelector,
  ],
  (
    events,
    eventStats,
    orders,
    eventsLoading,
    eventsErrors,
    usersState,
    promoCodes,
    affiliates,
    sessions,
  ) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const { user } = usersState;
    const { id: userId } = user || {};
    let allKeys = [...Object.keys(events), ...Object.keys(eventsLoading)];
    allKeys = [...new Set(allKeys)];
    return allKeys.reduce((acc, eventId) => {
      const { admins, scanners, name } = events[eventId] || {};
      const stats = eventStats[eventId] || {};
      const publicUrl = `${baseUrl}/events/${getSlug(name)}-tickets-${eventId}`;
      return {
        ...acc,
        [eventId]: {
          ...events[eventId],
          loading: eventsLoading[eventId],
          orders: orders[eventId],
          error: eventsErrors[eventId],
          userIsAdmin: (admins || []).includes(userId),
          userIsScanner: (scanners || []).includes(userId),
          publicUrl,
          stats,
          promoCodes: promoCodes[eventId],
          affiliates: affiliates[eventId],
          sessions: sessions[eventId],
        },
      };
    }, {});
  },
);

const eventsWithEnhancedTicketTypeSelector = createSelector(
  [eventsWithExtrasSelector, ticketTypeStatsSelector],
  (events, ticketTypeStats) => {
    const nowTimestamp = Timestamp.now();
    return Object.keys(events).reduce((acc, eventId) => {
      const stats = ticketTypeStats[eventId] || [];
      const ticketTypes = (events[eventId].ticketTypes || []).map(
        (ticketType) => {
          const { availabilityStartsAt, availabilityEndsAt } = ticketType;
          const ttStats = stats.find(({ id }) => id == ticketType.key);
          const { sold, capacity } = ttStats || {};
          let status;
          let statusDescription;
          if (capacity && sold >= capacity) {
            status = "soldOut";
          } else if (availabilityStartsAt.seconds > nowTimestamp.seconds) {
            status = "pending";
          } else if (availabilityEndsAt.seconds < nowTimestamp.seconds) {
            status = "ended";
          } else {
            status = "active";
          }
          return { ...ticketType, stats: ttStats, status };
        },
      );

      const { totalSoldCount, totalSoldUsdCents } = ticketTypes.reduce(
        ({ totalSoldCount, totalSoldUsdCents }, { stats, usdCents }) => {
          if (stats) {
            return {
              totalSoldCount: totalSoldCount + stats.sold,
              totalSoldUsdCents: totalSoldUsdCents + stats.sold * usdCents,
            };
          } else {
            return { totalSoldCount, totalSoldUsdCents };
          }
        },
        { totalSoldCount: 0, totalSoldUsdCents: 0 },
      );

      return {
        ...acc,
        [eventId]: {
          ...events[eventId],
          ticketTypes,
          totalSoldCount,
          totalSoldUsdCents,
        },
      };
    }, {});
  },
);

const statusForEvent = (event) => {
  const { ticketTypes, publishedAt, endsAt, stats } = event;
  const { sold, capacity } = stats;
  let status;
  let firstPendingStartsAt;
  let lastActiveEndsAt;
  if (Boolean(capacity) && sold >= capacity) {
    status = "soldOut";
  } else if (!publishedAt) {
    status = "draft";
  } else if (endsAt && endsAt.toMillis() < Timestamp.now().toMillis()) {
    status = "ended";
  } else {
    let allPending = true;
    let anyActive = false;
    let allEndedOrSoldOut = true;

    ticketTypes.forEach(
      ({ status, availabilityStartsAt, availabilityEndsAt }) => {
        switch (status) {
          case "soldOut":
            allPending = false;
            break;

          case "pending":
            allEndedOrSoldOut = false;
            if (
              !firstPendingStartsAt ||
              (availabilityStartsAt &&
                availabilityStartsAt.toMillis() <
                  firstPendingStartsAt.toMillis())
            ) {
              firstPendingStartsAt = availabilityStartsAt;
            }
            break;

          case "ended":
            allPending = false;
            break;

          case "active":
            allEndedOrSoldOut = false;
            allPending = false;
            anyActive = true;
            if (
              !lastActiveEndsAt ||
              (availabilityEndsAt &&
                availabilityEndsAt.toMillis() > lastActiveEndsAt)
            ) {
              lastActiveEndsAt = availabilityEndsAt;
            }
            break;
        }
      },
    );

    if (allPending) {
      status = "pending";
    } else if (anyActive) {
      status = "onSale";
    } else if (allEndedOrSoldOut) {
      status = "soldOut";
    }
  }

  return { status, firstPendingStartsAt, lastActiveEndsAt };
};

const eventsWithTimelineSelector = createSelector(
  [eventsWithEnhancedTicketTypeSelector],
  (events) => {
    return Object.keys(events).reduce((acc, eventId) => {
      const event = events[eventId];

      return {
        ...acc,
        [eventId]: {
          ...event,
          ...statusForEvent(event),
        },
      };
    }, {});
  },
);

const eventsWithPromoCodeTimelineSelector = createSelector(
  [eventsWithTimelineSelector],
  (events) => {
    const nowTimestamp = Timestamp.now();
    return Object.keys(events).reduce((acc, eventId) => {
      const event = events[eventId];
      const { promoCodes, ticketTypes: eventTicketTypes } = event;
      const eventPromoCodes = (promoCodes || []).map((promoCode) => {
        const {
          name,
          ticketTypes,
          discountType,
          discountAmount,
          ticketTypeSelection,
          capacity,
          timesUsed,
          promoStart,
          startsAt,
          promoEnd,
          endsAt,
          enabled,
        } = promoCode;

        const appliedTicketTypes =
          ticketTypeSelection == "all"
            ? eventTicketTypes
            : eventTicketTypes.filter(({ key }) => ticketTypes.includes(key));

        const { status: eventStatus } = statusForEvent({
          ...event,
          ticketTypes: appliedTicketTypes,
        });

        let status = "onSale";
        if (!enabled) {
          status = "disabled";
        } else {
          switch (eventStatus) {
            case "soldOut":
              status = "ticketsSoldOut";
              break;
            case "draft":
              status = "eventDraft";
              break;
            case "ended":
              status = "eventEnded";
              break;
            case "pending":
              status = "ticketSalesPending";
              break;
            case "onSale":
              if (
                promoStart === "scheduled" &&
                startsAt.seconds > nowTimestamp.seconds
              ) {
                status = "promoPending";
              } else if (
                promoEnd === "scheduled" &&
                endsAt.seconds < nowTimestamp.seconds
              ) {
                status = "promoEnded";
              } else if (Boolean(capacity) && timesUsed >= capacity) {
                status = "promoSoldOut";
              }
              break;
          }
        }

        return { ...promoCode, status };
      });
      const statusSortOrderMap = {
        onSale: 0,
        eventDraft: 1,
        ticketSalesPending: 2,
        ticketSalesPending: 3,
        promoPending: 4,
        promoEnded: 5,
        eventEnded: 6,
        promoSoldOut: 7,
        ticketsSoldOut: 8,
        disabled: 9,
      };
      var sortedPromoCodes = chain(eventPromoCodes)
        .sortBy(({ name }) => {
          return name;
        })
        .sortBy(({ status }) => {
          return -statusSortOrderMap[status] || -100;
        })
        .value();
      return {
        ...acc,
        [eventId]: { ...event, promoCodes: sortedPromoCodes },
      };
    }, {});
  },
);

const eventsWithSubcollectionsSelector = createSelector(
  [eventsWithPromoCodeTimelineSelector, ticketsSortedSelector],
  (events, tickets) => {
    return Object.keys(events).reduce((acc, eventId) => {
      return {
        ...acc,
        [eventId]: {
          ...events[eventId],
          tickets: tickets[eventId],
        },
      };
    }, {});
  },
);

const eventsListSelector = createSelector(
  [eventsWithSubcollectionsSelector],
  (events) => {
    return Object.keys(events).map((eventId) => {
      return events[eventId];
    }, {});
  },
);

export const adminEventsSelector = createStructuredSelector({
  newEvent: newEventWithLoading,
  duplicatedEvent: duplicatedEventWithLoading,
  events: eventsListSelector,
  eventsLoading: eventsListLoadingSelector,
  eventsFetched: eventsListFetchedSelector,
  duplicatingEvent: duplicatingEventSelector,
  eventsIndex: eventsWithSubcollectionsSelector,
});

export default adminEventsSlice.reducer;
export const {
  resetEvents,
  setEvent,
  clearNewEvent,
  clearDuplicatedEvent,
  setEventLoading,
  setEventUpdateError,
  receiveTickets,
} = adminEventsSlice.actions;
