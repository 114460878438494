import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext, Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import * as yup from "yup";
import { Form, Formik, getIn } from "formik";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  IconButton,
  Autocomplete,
} from "@mui/material";
import { PerformerView } from "./PerformerView";
import {
  removePerformerFromEvent,
  addPerformerToEvent,
  updateEvent,
} from "../../../redux/features/adminEventsSlice";
import ConfirmationDialog from "../../ConfirmationDialog";
import {
  fetchOrganizationById,
  removePerformerFromOrganization,
} from "../../../redux/features/organizationsSlice";

export const lockAxisStyle = (style) => {
  if (style?.transform) {
    const axisLockY = `translate(0px, ${style.transform.split(",").pop()}`;
    return {
      ...style,
      transform: axisLockY,
    };
  }
  return style;
};

export const getItemStyle = (draggableStyle) => ({
  userSelect: "none",
  margin: `0 0 20px 0`,
  ...draggableStyle,
});

export const PerformersList = ({}) => {
  const dispatch = useDispatch();
  const [showConfirmDeleteFromEvent, setShowConfirmDeleteFromEvent] =
    useState(false);
  const [showConfirmDeleteFromOrg, setShowConfirmDeleteFromOrg] =
    useState(false);
  const [showAddPerformerModal, setShowAddPerformerModal] = useState(false);
  const [typedValue, setTypedValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const { event, organization } = useOutletContext();
  const { id: organizationId, performers: orgPerformers } = organization;
  const { id: eventId, performers, stats } = event;

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const newList = [...performers];
    const [removed] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, removed);
    await dispatch(updateEvent({ eventId, values: { performers: newList } }));
  };

  let orgPerformersOptions = (orgPerformers || [])
    .sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    })
    .map((performer) => {
      return { label: performer.name, existing: true };
    });

  if (typedValue) {
    const typedIsExisting = (orgPerformers || []).find(
      ({ name }) => name === typedValue,
    );
    if (!Boolean(typedIsExisting)) {
      orgPerformersOptions = [
        { label: typedValue, existing: false },
        ...orgPerformersOptions,
      ];
    }
  }

  const selectedIsExisting = (performers || []).find(
    ({ name }) => name === selectedValue?.label,
  );

  return (
    <Box>
      <Modal open={showAddPerformerModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "100%", sm: "100%", md: 600 },
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            height: "90vh",
            maxHeight: "90vh",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={"20px"}
            flexGrow={"1"}
          >
            <Typography variant="h5">Add Performer</Typography>
            <Autocomplete
              onInputChange={(event, newInputValue) => {
                setTypedValue(newInputValue);
              }}
              onChange={(event, newValue) => {
                setSelectedValue(newValue);
              }}
              onBlur={() => {
                if (typedValue !== (selectedValue || {}).label) {
                  setSelectedValue({ label: typedValue });
                }
              }}
              value={selectedValue}
              inputValue={typedValue}
              options={orgPerformersOptions}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Full Name" />
              )}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                  <Box
                    key={key}
                    component="li"
                    {...optionProps}
                    display={"flex"}
                    gap={"5px"}
                    width={"100%"}
                  >
                    <Typography
                      color={option.existing ? "" : "primary"}
                      flexGrow={"1"}
                    >
                      {option.label}
                    </Typography>
                    <IconButton
                      sx={{ marginLeft: "auto" }}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowConfirmDeleteFromOrg(option.label);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                );
              }}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "20px",
              marginTop: "20px",
            }}
          >
            <Button
              color="secondary"
              variant="contained"
              sx={{ flexGrow: 1 }}
              onClick={async () => {
                setShowAddPerformerModal(false);
                setTypedValue("");
                setSelectedValue(null);
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={
                Boolean(selectedIsExisting) ||
                (!Boolean(selectedValue) && typedValue.length == 0)
              }
              variant="contained"
              sx={{ flexGrow: 1 }}
              onClick={() => {
                setShowAddPerformerModal(false);
                dispatch(
                  addPerformerToEvent({
                    eventId,
                    performerName: selectedValue.label,
                  }),
                );
                setTypedValue("");
                setSelectedValue(null);
              }}
            >
              Add
            </Button>
          </Box>
        </Box>
      </Modal>
      <ConfirmationDialog
        open={Boolean(showConfirmDeleteFromEvent)}
        title={`Remove ${showConfirmDeleteFromEvent}?`}
        message={
          "Are you sure you want to remove this performer from the event?"
        }
        buttonDefsOverride={[
          {
            text: "Cancel",
            onClick: () => {
              setShowConfirmDeleteFromEvent(null);
            },
          },
          {
            text: "Delete",
            onClick: async () => {
              setShowConfirmDeleteFromEvent(null);
              dispatch(
                removePerformerFromEvent({
                  eventId,
                  name: showConfirmDeleteFromEvent,
                }),
              );
            },
          },
        ]}
      />
      <ConfirmationDialog
        open={Boolean(showConfirmDeleteFromOrg)}
        title={`Delete ${showConfirmDeleteFromOrg} from organization?`}
        message={
          "Are you sure you want to delete this performer form your organization? Events with this performer assigned will not be affected"
        }
        buttonDefsOverride={[
          {
            text: "Cancel",
            onClick: () => {
              setShowConfirmDeleteFromOrg(null);
            },
          },
          {
            text: "Delete",
            onClick: async () => {
              setShowConfirmDeleteFromOrg(null);
              dispatch(
                removePerformerFromOrganization({
                  organizationId,
                  name: showConfirmDeleteFromOrg,
                }),
              );
            },
          },
        ]}
      />
      <Box
        width={"100%"}
        display={"flex"}
        alignItems={{ xs: "left", md: "center" }}
        gap={"5px"}
        sx={{ marginTop: "20px" }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Typography variant="h2" flexGrow={1}>
          Performers
        </Typography>
        <Box>
          <Button
            onClick={async () => {
              await dispatch(fetchOrganizationById({ organizationId }));
              setShowAddPerformerModal(true);
            }}
          >
            New Performer
          </Button>
        </Box>
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ width: "100%", marginTop: "20px" }}
            >
              {(performers || []).map((performer, index) => {
                return (
                  <Draggable
                    key={`performer-${index}`}
                    draggableId={`performer-${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={lockAxisStyle(
                          getItemStyle(provided.draggableProps.style),
                        )}
                      >
                        <PerformerView
                          handleDelete={setShowConfirmDeleteFromEvent}
                          dhProps={provided.dragHandleProps}
                          {...{
                            index,
                            performer,
                            event,
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};
