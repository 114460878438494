import React from "react";
import { Box } from "@mui/material";
import { Outlet, useOutletContext, useParams } from "react-router-dom";

const EventPerformers = ({}) => {
  const { event, organization } = useOutletContext();

  return (
    <Box>
      <Outlet context={{ event, organization }} />
    </Box>
  );
};

export default EventPerformers;
