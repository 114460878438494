import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useParams,
  useLocation,
  useNavigate,
  Outlet,
  Link,
  matchPath,
} from "react-router-dom";
import {
  buildNewEvent,
  adminEventsSelector,
  fetchEventById,
  clearNewEvent,
  setEventUpdateError,
  clearDuplicatedEvent,
} from "../../redux/features/adminEventsSlice";
import { usersSliceSelector } from "../../redux/features/usersSlice";
import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Tabs,
  Tab,
  Typography,
  Snackbar,
  Select,
  MenuItem,
  Dialog,
  Toolbar,
  IconButton,
  DialogTitle,
  DialogContent,
  AppBar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { QRCodeCanvas } from "qrcode.react";

import EventHeader from "./EventHeader";
import theme from "../../theme";
import { organizationsSliceSelector } from "../../redux/features/organizationsSlice";
import CloseIcon from "@mui/icons-material/Close";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const loadingOverlay = (show) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={!!show}
    >
      <Box display={"flex"} alignItems={"center"} gap={"20px"}>
        <CircularProgress color="inherit" />{" "}
        <Typography variant="h2">Working...</Typography>
      </Box>
    </Backdrop>
  );
};
const errorSnackbar = (errorMessage, handleClose) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={!!errorMessage}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};

const QrCodeDialog = ({ open, handleClose, url }) => {
  return (
    <Dialog
      fullScreen
      open={open}
      handleClose={handleClose}
      aria-labelledby="qr-code-dialog"
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Scan the QR Code
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <QRCodeCanvas value={url} size={256} />
      </DialogContent>
    </Dialog>
  );
};

const AdminEventView = ({}) => {
  const dispatch = useDispatch();
  const eventsState = useSelector((state) => adminEventsSelector(state));
  const usersState = useSelector((state) => usersSliceSelector(state));
  const organizationsState = useSelector((state) =>
    organizationsSliceSelector(state),
  );
  const [showingQrCode, setShowingQrCode] = useState(null);

  const { pathname } = useLocation();

  const params = useParams();
  const navigate = useNavigate();

  const creatingNew = pathname == "/events/new";
  const duplicating = pathname == "/events/duplicated";
  const eventId = params.eventId;
  const { authState } = usersState;
  const { currentOrganization } = organizationsState;

  const pathIsBase = pathname === `/events/${eventId}`;

  let event;
  if (duplicating) {
    event = eventsState.duplicatedEvent;
  } else if (creatingNew) {
    event = eventsState.newEvent;
  } else {
    event = eventsState.eventsIndex[eventId];
  }
  const { ticketTypes, tickets, loading, error, publicUrl } = event || {};

  useEffect(() => {
    if (!currentOrganization || !authState) {
      return;
    }
    if (duplicating && event) {
      console.log("duplicated - forwarding");
      const afterDupe = async () => {
        await dispatch(clearDuplicatedEvent());
        navigate(`/events/${event.id}`);
      };
      afterDupe();
    } else if (!event && !creatingNew) {
      console.log("no event. fetching.");
      dispatch(fetchEventById({ eventId, includeExtras: true }));
    } else if (!event && creatingNew) {
      console.log("new new event. creating.");
      dispatch(buildNewEvent());
    } else if (creatingNew && event.id) {
      const afterCreate = async () => {
        await dispatch(clearNewEvent());
        navigate(`/events/${event.id}`);
      };
      afterCreate();
    }
  }, [event, authState, ticketTypes, tickets, currentOrganization]);

  const dashboardTab = {
    label: "Dashboard",
    path: "dashboard",
    props: { event },
  };
  const basicInfoTab = {
    label: "Basic Info",
    path: "basic",
  };
  const performersTab = {
    label: "Performers",
    path: "performers",
  };
  const mediaTab = {
    label: "Media",
    path: "media",
  };
  const orderDataTab = {
    label: "Order Forms",
    path: "orderForms",
  };
  const ticketTypesTab = {
    label: "Ticket Types",
    path: "ticketTypes",
  };
  const promoCodesTab = {
    label: "Promo Codes",
    path: "promoCodes",
  };
  const affiliatesTab = { label: "Affiliates", path: "affiliates" };
  const sessionsTab = { label: "Sessions", path: "sessions" };
  const publishTab = {
    label: "Publish",
    path: "publish",
  };

  const ordersTab = {
    label: "Orders",
    path: "orders",
  };

  const ticketsTab = {
    label: "Attendees",
    path: "tickets",
  };

  const { userIsAdmin, userIsScanner } = event || {};

  let tabs;
  if (creatingNew) {
    tabs = [basicInfoTab];
  } else if (userIsAdmin) {
    tabs = [
      dashboardTab,
      basicInfoTab,
      performersTab,
      mediaTab,
      ticketTypesTab,
      orderDataTab,
      promoCodesTab,
      affiliatesTab,
      sessionsTab,
      publishTab,
      ordersTab,
      ticketsTab,
    ];
  } else if (userIsScanner) {
    tabs = [ticketsTab];
  } else {
    tabs = [];
  }

  const routes = [
    basicInfoTab,
    mediaTab,
    performersTab,
    promoCodesTab,
    affiliatesTab,
    sessionsTab,
    orderDataTab,
    ticketTypesTab,
    publishTab,
    ticketsTab,
    ordersTab,
    dashboardTab,
  ].map((tab) => tab.path);
  const currentRoute = routes.find((route) => {
    return matchPath(
      { path: `events/:eventId/${route}`, end: false },
      pathname,
    );
  });

  useEffect(() => {
    if (pathIsBase && tabs.length > 0) {
      navigate(tabs[0].path);
    }
  }, [pathIsBase, tabs]);

  if (eventsState.duplicatingEvent) {
    return loadingOverlay(true);
  }

  if (!event || (!currentRoute && !creatingNew)) {
    return null;
  }

  return (
    <Box>
      <QrCodeDialog
        open={Boolean(showingQrCode)}
        url={showingQrCode}
        handleClose={() => {
          setShowingQrCode(null);
        }}
      />
      {errorSnackbar(error, () => {
        dispatch(setEventUpdateError({ eventId, error: null }));
      })}
      {loadingOverlay(loading)}
      <EventHeader
        creatingNew={creatingNew}
        event={event}
        onShowQrCodeClick={() => {
          setShowingQrCode(publicUrl);
        }}
      />
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          backgroundColor: theme.palette.primary.light,
        }}
      >
        {!creatingNew && (
          <>
            <Select
              value={currentRoute}
              sx={{
                marginLeft: "5px",
                marginRight: "5px",
                marginBottom: "5px",
                // display: { xs: "block", sm: "block", md: "none" },
                display: { xs: "block", sm: "block", md: "block" },
              }}
            >
              {tabs.map(({ path, label }, i) => {
                return (
                  <MenuItem
                    value={path}
                    component={Link}
                    to={`./${path}`}
                    key={`tab-menu-${path}`}
                  >
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
            {/* <Tabs
              value={currentRoute}
              aria-label="Event selector"
              sx={{
                display: { xs: "none", sm: "none", md: "block" },
              }}
            >
              {tabs.map(({ path, label }, i) => {
                return (
                  <Tab
                    key={path}
                    label={label}
                    sx={{ Right: "0px" }}
                    component={Link}
                    to={`./${path}`}
                    value={path}
                  />
                );
              })}
            </Tabs> */}
          </>
        )}
      </Box>
      <Outlet
        context={{
          event,
          organization: currentOrganization,
          creatingNew,
          handleShowQrCode: (url) => {
            setShowingQrCode(url);
          },
        }}
      />
    </Box>
  );
};

export default AdminEventView;
